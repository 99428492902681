import { Button, Container, FlexGrid, Header, Image, Text } from '@gasbuddy/react-components';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import useImageSrcBuilder from '../../../../lib/hooks/useImageSrcBuilder';
import { Block } from '../../../prop-types/cms-page';
import Markup from '../../Markup';
import styles from '../LandingPageTemplate2.module.css';

const cx = classNames.bind(styles);

export function About({ blocks, imageRight }) {
  const imageToSrc = useImageSrcBuilder();

  return blocks.map(block => (
    <div className={cx('section')} key={block.title || block.description}>
      <Container>
        <FlexGrid container className={cx('centerItems')}>
          {!imageRight && block.image?.url && (
            <FlexGrid.Column tablet={5}>
              <Image alt={block.alt} className={cx('aboutImage', 'left')} fluid src={imageToSrc(block.image)} />
            </FlexGrid.Column>
          )}
          <FlexGrid.Column tablet={block.image ? 7 : 12}>
            <Header
              as="h2"
              className={cx('title', !block.titleImage ? (block.titleSize || 'medium') : '')}
              style={{ textAlign: block.titleAlignment || 'left' }}
            >
              {block.titleImage ? (
                <Image alt={block.title} className={cx('titleImage')} src={imageToSrc(block.titleImage)} />
              ) : (
                <Markup source={block.title} />
              )}
            </Header>
            <Text
              as="p"
              className={cx('description', block.descriptionSize || '')}
              style={{ textAlign: block.descriptionAlignment || 'left' }}
            >
              <Markup source={block.description} />
            </Text>
            {block.ctaLabel && block.ctaUrl && (
              <Button as="a" className={cx('cta')} href={block.ctaUrl} primary wide>{block.ctaLabel}</Button>
            )}
            {block.ctaDisclaimer && (
              <Text className={cx('ctaDisclaimer')} size="sm">{block.ctaDisclaimer}</Text>
            )}
          </FlexGrid.Column>
          {imageRight && block.image?.url && (
            <FlexGrid.Column tablet={5}>
              <Image alt={block.alt} className={cx('aboutImage', 'right')} fluid src={imageToSrc(block.image)} />
            </FlexGrid.Column>
          )}
        </FlexGrid>
      </Container>
    </div>
  ));
}

About.propTypes = {
  blocks: PropTypes.arrayOf(Block),
};

About.defaultProps = {
  blocks: [],
};
