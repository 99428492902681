import { Container, FlexGrid, Header } from '@gasbuddy/react-components';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { FAQPropType } from '../../../prop-types/cms-page';
import Markup from '../../Markup';
import styles from '../LandingPageTemplate2.module.css';
import Markdown from '../../Markdown';

const cx = classNames.bind(styles);

function QuestionListItem({ children, question }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleClick = useCallback(() => {
    setIsOpen(isCurrentlyOpen => !isCurrentlyOpen);
  }, []);

  // See below link for reasoning behind button element
  // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/no-noninteractive-element-interactions.md#case-this-is-a-heading-that-expandscollapses-content-on-the-package
  return (
    <div className={cx('item', { active: isOpen })} itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <button className={cx('reset')} onClick={handleToggleClick} type="button">
        <span className={cx('chevron', isOpen ? 'up' : 'down')} />
        <strong className={cx('question')} itemProp="name"><Markup source={question} /></strong>
      </button>
      <div className={cx('answer')} itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
        <div itemProp="text">{children}</div>
      </div>
    </div>
  );
}

QuestionListItem.propTypes = {
  children: PropTypes.node,
  question: PropTypes.string,
};

QuestionListItem.defaultProps = {
  children: undefined,
  question: undefined,
};

export function FAQs({ faqs }) {
  const { questions, title } = faqs || {};
  const faqSet1 = questions?.slice(0, Math.ceil(questions.length / 2));
  const faqSet2 = questions?.slice(faqSet1.length);

  if (faqSet1.length === 0) {
    return null;
  }

  return (
    <section id="faq" className={cx('faq', 'section')}>
      <Container>
        {title && (
          <Header as="h2">
            <Markup source={title} escapeHtml={false} />
          </Header>
        )}
        <FlexGrid className={cx('accordionRow')} container itemScope itemType="https://schema.org/FAQPage">
          <FlexGrid.Column className={cx('accordionCol')} tablet={6}>
            {faqSet1.map(({ faq }) => (
              <QuestionListItem key={faq.title} question={faq.question}>
                <Markdown>{faq.answer}</Markdown>
              </QuestionListItem>
            ))}
          </FlexGrid.Column>

          <FlexGrid.Column className={cx('accordionCol')} tablet={6}>
            {faqSet2.map(({ faq }) => (
              <QuestionListItem key={faq.title} question={faq.question}>
                <Markdown>{faq.answer}</Markdown>
              </QuestionListItem>
            ))}
          </FlexGrid.Column>
        </FlexGrid>
      </Container>
    </section>
  );
}

FAQs.propTypes = {
  faqs: PropTypes.shape({
    questions: PropTypes.arrayOf(FAQPropType),
    title: PropTypes.string,
  }),
};

FAQs.defaultProps = {
  faqs: undefined,
};
