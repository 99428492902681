import { Container } from '@gasbuddy/react-components';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Block } from '../../../prop-types/cms-page';
import Markup from '../../Markup';
import styles from '../LandingPageTemplate2.module.css';

const cx = classNames.bind(styles);

export function Terms({ blocks }) {
  return (
    <Fragment>
      {blocks?.length > 0 && (
        <section id="terms" className={cx('termsAndConditions')}>
          <Container className={cx('terms')}>
            <Markup source={blocks[0].description} />
          </Container>
        </section>
      )}
    </Fragment>
  );
}

Terms.propTypes = {
  blocks: PropTypes.arrayOf(Block),
};

Terms.defaultProps = {
  blocks: [],
};
