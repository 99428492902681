import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Loader } from '@gasbuddy/react-components';
import loadable from '@loadable/component';
import { useLocation } from 'react-router';
import PageType from '../../constants/pageType';
import PageLayout from '../PageLayout/PageLayout';

const LPTemplate1 = loadable(() => import('../LandingPageTemplate1'));
const LPTemplate2 = loadable(() => import('../LandingPageTemplate2'));
const AboutUsTemplate = loadable(() => import('../AboutUsPage'));

export const GET_PAGE = gql`
  query($url: String!) {
    page(url: $url) {
      ... on CMSAboutUsPage {
        member {
          bio
          bioImage {
            height
            url
            width
          }
          firstName
          lastName
          link
          memberExecutiveTeam
          title
        }
        meta {
          content
          title
          description
          image {
            url
          }
          name
          provider
          title
          type
        }
        pressCoverage {
          alt
          content
          image {
            height
            url
            width
          }
        }
        sections {
          ctaUrl
          ctaLabel
          ctaDisclaimer
          description
          image {
            url
          }
          imageAlt
          mobileImage {
            url
          }
          name
          title
        }
        title
        type
        url
      }
      ... on CMSLandingPage {
        actions {
          eventAttributes {
            name
            value
          }
          name
          tagType
          trigger
          type
          value
        }
        adsSdk
        canonicalUrl
        faqs {
          questions {
            faq {
              answer
              question
              title
            }
          }
          title
        }
        imageSets {
          images {
            alt
            content
            image {
              height
              url
              width
            }
          }
          name
        }
        meta {
          content
          description
          image {
            url
          }
          name
          provider
          title
          type
        }
        partnerLogo {
          alt
          image {
            height
            url
            width
          }
        }
        partnerMobileLogo {
          alt
          image {
            height
            url
            width
          }
        }
        product {
          description
          id
          name
          offers {
            price {
              currency
              amount
            }
            url
          }
          productImages {
            image {
              url
            }
          }
        }
        saveHeadline
        sectionList {
          backgroundColor
          blocks {
            alt
            ctaDisclaimer
            ctaLabel
            ctaUrl
            customHtml
            description
            descriptionAlignment
            descriptionSize
            image {
              url
            }
            title
            titleAlignment
            titleImage {
              url
            }
            titleSize
          }
          ctaDisclaimer
          ctaLabel
          ctaUrl
          displayName
          name
          subtitle
          textColor
          title
          titleImage {
            url
          }
        }
        sections {
          ctaLabel
          ctaUrl
          ctaDisclaimer
          description
          footer
          image {
            url
          }
          imageAlt
          mobileImage {
            url
          }
          name
          title
        }
        statsHeadline
        quotes {
          quote {
            alt
            image {
              height
              width
              url
            }
            name
            quote
            title
          }
        }
        title
        type
        url
      }
    }
  }
`;

function CMSPage() {
  const { pathname } = useLocation();
  const { data, error, loading } = useQuery(GET_PAGE, {
    variables: {
      url: pathname.substring(1),
    },
  });
  const page = data?.page;

  if (error) {
    throw new Error(error.message);
    // throw new Error('An error occurred while loading this page');
  }

  if (loading) {
    return (
      <Loader />
    );
  }

  let pageContent;

  if (page?.type === PageType.About) {
    pageContent = (
      <AboutUsTemplate page={page} />
    );
  } else if (page?.type === PageType.Generic) {
    pageContent = (
      <LPTemplate2 page={page} />
    );
  } else {
    pageContent = (
      <LPTemplate1 page={page} />
    );
  }

  if (page?.partnerMobileLogo || page?.partnerLogo) {
    return pageContent;
  }

  return (
    <PageLayout>
      {pageContent}
    </PageLayout>
  );
}

export default CMSPage;
