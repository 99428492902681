import { Container, Header, Image, Link } from '@gasbuddy/react-components';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import useImageSrcBuilder from '../../../../lib/hooks/useImageSrcBuilder';
import { Block } from '../../../prop-types/cms-page';
import Markup from '../../Markup';
import styles from '../LandingPageTemplate2.module.css';

const cx = classNames.bind(styles);

export function BrandShowcase({ blocks, title }) {
  const imageToSrc = useImageSrcBuilder();
  const imageBlocks = blocks.filter(block => !block.customHtml);
  const customHtmlBlock = blocks.find(block => !!block.customHtml);

  return (
    <div className={cx('section')}>
      <Container>
        <Header as="h2" className={cx('title')}>{title}</Header>
        <div className={cx('showcase')}>
          {imageBlocks.map(block => (
            <Link className={cx('item')} key={block.alt || block.image.url} to={block.ctaUrl}>
              <Image alt={block.alt} src={imageToSrc(block.image)} />
            </Link>
          ))}
        </div>
        {!!customHtmlBlock && (
          <div className={cx('listen')}>
            <Markup source={customHtmlBlock.customHtml} />
          </div>
        )}
      </Container>
    </div>
  );
}

BrandShowcase.propTypes = {
  blocks: PropTypes.arrayOf(Block),
  title: PropTypes.string,
};

BrandShowcase.defaultProps = {
  blocks: [],
  title: undefined,
};
