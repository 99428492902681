import { Button, Container, FlexGrid, Header, Image, Text } from '@gasbuddy/react-components';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import useImageSrcBuilder from '../../../../lib/hooks/useImageSrcBuilder';
import { Block, ImagePropType } from '../../../prop-types/cms-page';
import Markup from '../../Markup';
import styles from '../LandingPageTemplate2.module.css';
import { noop } from '../../../../lib/utils';

const cx = classNames.bind(styles);

export function MetricsRow({
  backgroundColor,
  blocks,
  ctaDisclaimer,
  ctaLabel,
  ctaUrl,
  onClick,
  subtitle,
  textColor,
  title,
  titleImage,
}) {
  const imageToSrc = useImageSrcBuilder();
  const sectionStyles = {};
  if (textColor) {
    sectionStyles.color = textColor;
  }
  if (backgroundColor) {
    sectionStyles.backgroundColor = backgroundColor;
  }

  return (
    <section className={cx('section', 'metricsRow')} style={sectionStyles}>
      <Container>
        <Header as="h2" className={cx('header')}>
          {titleImage?.url ? (
            <Image alt={title} className={cx('titleImage')} src={imageToSrc(titleImage)} />
          ) : (
            <Markup source={title} />
          )}
        </Header>
        <Header as="h3" className={cx('subtitle')}>
          <Markup source={subtitle} />
        </Header>
        <FlexGrid container>
          {blocks.map(block => (
            <FlexGrid.Column
              key={block.description}
              className={cx('description')}
              tablet={6}
              desktop={Math.max(Math.floor(12 / blocks.length), 3)}
            >
              <Image alt={block.alt} className={cx('metricImage')} src={imageToSrc(block.image)} />
              <br />
              <Text as="p" style={{ textAlign: block.descriptionAlignment || 'center' }}>
                <Markup source={block.description} />
              </Text>
            </FlexGrid.Column>
          ))}
        </FlexGrid>
        <br />
        {ctaLabel && ctaUrl && (
          <Button
            as="a"
            className={cx('cta')}
            href={ctaUrl}
            onClick={onClick}
            primary
            size="lg"
            wide
          >
            {ctaLabel}
          </Button>
        )}
        {ctaDisclaimer && (
          <Text className={cx('ctaDisclaimer')} size="sm">{ctaDisclaimer}</Text>
        )}
      </Container>
    </section>
  );
}

MetricsRow.propTypes = {
  backgroundColor: PropTypes.string,
  blocks: PropTypes.arrayOf(Block),
  ctaDisclaimer: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  onClick: PropTypes.func,
  subtitle: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  titleImage: ImagePropType,
};

MetricsRow.defaultProps = {
  backgroundColor: undefined,
  blocks: [],
  ctaDisclaimer: undefined,
  ctaLabel: undefined,
  ctaUrl: undefined,
  onClick: noop,
  subtitle: undefined,
  textColor: undefined,
  title: undefined,
  titleImage: undefined,
};
