import { Container, FlexGrid, Header, Image, Text } from '@gasbuddy/react-components';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import useImageSrcBuilder from '../../../../lib/hooks/useImageSrcBuilder';
import { Block } from '../../../prop-types/cms-page';
import Markup from '../../Markup';
import styles from '../LandingPageTemplate2.module.css';

const cx = classNames.bind(styles);

export function Bios({ blocks, title }) {
  const imageToSrc = useImageSrcBuilder();

  return (
    <div className={cx('section')}>
      <Container>
        {title && (
          <Header as="h2" className={cx('title')}>{title}</Header>
        )}
        <div className={cx('bios')}>
          {blocks.map(block => (
            <FlexGrid className={cx('bio')} key={block.title || block.description}>
              {block.image?.url && (
                <FlexGrid.Column className={cx('imgCol')}>
                  <Image alt={block.alt} src={imageToSrc(block.image)} />
                </FlexGrid.Column>
              )}
              <FlexGrid.Column className={cx('desc')}>
                <Header as="h3" className={cx('title')}>{block.title}</Header>
                <Text as="p">
                  <Markup source={block.description} />
                </Text>
              </FlexGrid.Column>
            </FlexGrid>
          ))}
        </div>
      </Container>
    </div>
  );
}

Bios.propTypes = {
  blocks: PropTypes.arrayOf(Block),
  title: PropTypes.string,
};

Bios.defaultProps = {
  blocks: [],
  title: undefined,
};
